export const required = {
  required: true,
  message: '请填写此项内容',
  trigger: 'blur',
}
export function len(max = 255, min = 0) {
  return {
    min,
    max,
    message: `长度在 ${min} 到 ${max} 个字符`,
    trigger: 'blur',
  }
}
