<template>
  <b-card v-loading="loading">
    <el-form
      ref="form"
      label-width="80px"
      :label-position="'right'"
      :rules="rules"
      :model="form"
    >
      <b-row>
        <b-col :md="colNum[t]">
          <el-form-item
            label="模板名称"
            prop="templateName"
          >
            <el-input
              v-model="form.templateName"
              placeholder="请输入模板名称"
            />
          </el-form-item>
        </b-col>
        <b-col :md="colNum[t]">
          <el-form-item
            label="模板编号"
            prop="code"
            :rules="[{ required: true, message: '模板编号不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.code"
              :disabled="id!=='0' && c!=='3'"
              placeholder="请输入模板编号"
            />
          </el-form-item>
        </b-col>
        <b-col
          v-if="t === '2'"
          :md="colNum[t]"
        >
          <el-form-item label="语言">
            <el-input
              v-model="form.language"
              placeholder="请输入语言"
            />
          </el-form-item>
        </b-col>
        <b-col
          v-if="t === '2' || t === '1'"
          :md="colNum[t]"
        >
          <el-form-item label="地区">
            <el-input
              v-model="form.area"
              placeholder="请输入地区"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item
            label="模板说明"
            prop="description"
          >
            <el-input
              v-model="form.description"
              :autosize="autosize"
              type="textarea"
              placeholder="请输入模板说明"
            />
          </el-form-item>
        </b-col>
        <b-col
          md="12"
        >
          <el-form-item
            label="过滤信息"
            class="filter-item"
          >
            <el-tag
              v-for="tag in form.filterOptions"
              :key="tag"
              class="mr10"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              v-if="inputVisible"
              ref="saveTagInput"
              v-model="inputValue"
              class="input-new-tag"
              width="100px"
              size="mini"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            />
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
            >
              + 添加
            </el-button>
          </el-form-item>

        </b-col>
      </b-row>
    </el-form>
    <div class="flex-start match-height editor-wrap">
      <div
        v-if="form.templateType!==3"
        class="doc-left"
      >
        <div class="add-doc">
          <el-button
            class="btn-block text-center"
            size="small"
            type="primary"
            @click="saveModuleDialogShow(1)"
          >
            新增模块
          </el-button>
        </div>
        <VuePerfectScrollbar
          v-loading="tagLoading"
          :settings="settings"
        >
          <draggable
            v-model="form.contentList"
            tag="ul"
            handle=".mover"
            :options="{forceFallback:true, fallbackClass:'draggingStyle'}"
            @end="onEnd"
          >
            <li
              v-for="(item,index) in form.contentList"
              :key="index"
              :class="activeIndex === index ? 'doc-active' : ''"
              class="section-item"
              @click="sectionClick(item,index)"
            >
              <div
                v-show="!item.editor"
                class="flex-start mover"
                style="width: 100%"
              >
                <feather-icon
                  icon="CircleIcon"
                  class="mr10"
                />
                <el-tooltip
                  v-if="item.directoryName.length >= 14"
                  class="item"
                  effect="light"
                  :content="item.directoryName"
                  placement="top"
                >
                  <span
                    class="overflow-ellipsis directoryName"
                    style="max-width: 70%"
                  >{{ item.directoryName }}</span>
                </el-tooltip>
                <span
                  v-else
                  class="overflow-ellipsis directoryName"
                  style="max-width: 70%"
                >{{ item.directoryName }}</span>
              </div>
              <div
                v-show="!item.editor"
                class="flex-end section-operate"
              >
                <i
                  class="el-icon-edit mr10"
                  @click="saveModuleDialogShow(2,item)"
                />
                <i
                  class="el-icon-delete"
                  @click.stop="delSection(index)"
                />
              </div>
            </li>
          </draggable>
        </VuePerfectScrollbar>
      </div>
      <div class="doc-right">
        <div
          v-for="(item,index) in form.contentList"
          v-if="activeIndex === index"
          :key="index"
        >
          <EditorVue
            :content.sync="item"
            :style-value="{ height: '400px', overflowY: 'auto' }"
          />
        </div>
      </div>
    </div>
    <div class="text-center mt20">
      <el-button
        size="small"
        @click="back"
      >
        返 回
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="submit"
      >
        保 存
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="preview"
      >
        预 览
      </el-button>
    </div>
    <ModeleSave
      ref="modeleSave"
      :filter-options="form.filterOptions"
      @handleRefensh="addOrUpdateModule"
    />
  </b-card>
</template>

<script>

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { GetTemplateById, SaveOrUpdateTemplate } from '@/api/knowledgeBase/template'
import { error, success } from '@core/utils/utils'
import EditorVue from '@core/components/editor-vue/EditorVue.vue'
import ModeleSave from '@/views/template/ModuleSave.vue'

import { required, len } from '@/@core/utils/formRules'

export default {
  components: {
    VuePerfectScrollbar, draggable, EditorVue, ModeleSave,
  },
  data() {
    //    c: this.$route.params.c, // 新增1 修改 2 复制 3
    return {
      rules: {
        templateName: [required],
        description: [len(200)],
      },
      saveModuleType: 1, // 1新增 2 修改
      colNum: {
        4: 6,
        2: 3,
      },
      inputVisible: false,
      inputValue: '',
      countryProps: {
        emitPath: false,
      },
      form: {
        templateName: '',
        templateType: Number(this.$route.params.t),
        description: '',
        language: '',
        operateType: 1,
        contentList: [],
        filterOptions: [],
        code: '',
        area: '',
        labelIdList: [],
      },
      tagLoading: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30,
      },
      activeIndex: 0,
      id: this.$route.params.id, // code
      c: this.$route.params.c, // 新增1 修改 2 复制 3
      t: this.$route.params.t, // 类型 1: '隐私政策',  2: '隐私声明',  3: '制度流程',  4: '合作协议',
      htmlStr: '',
      editor: null,
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default',
      isInit: true,
      loading: false,
      backPath: {
        1: '/template-policy',
        2: '/template-statement',
        3: 'template-regulations',
        4: '/template-collect',
      },
      navLevelStr: {
        1: '隐私政策',
        2: '隐私声明',
        3: '制度流程',
        4: '合作协议',
      },
    }
  },
  created() {
    if (this.id && this.id !== '0') {
      this.getTemplateInfo()
    }
  },
  mounted() {
    this.navActiveInit(1, this.navLevelStr[this.t], '模板管理')
  },
  destroyed() {
    this.navActiveInit(2, this.navLevelStr[this.t], '模板管理', this.backPath[this.t])
  },
  methods: {
    addOrUpdateModule() {
      if (this.saveModuleType === 1) {
        if (!this.form.contentList) {
          this.form.contentList = []
        }
        this.form.contentList.push({
          content: '',
          directoryName: this.$refs.modeleSave.form.directoryName,
          filterOption: this.$refs.modeleSave.form.filterOption,
        })
        this.activeIndex = this.form.contentList.length - 1
        this.htmlStr = this.form.contentList[this.activeIndex].content
      } else {
        this.form.contentList[this.activeIndex].directoryName = this.$refs.modeleSave.form.directoryName
        this.form.contentList[this.activeIndex].filterOption = this.$refs.modeleSave.form.filterOption
      }
    },
    saveModuleDialogShow(saveType, item) {
      this.saveModuleType = saveType
      this.$refs.modeleSave.saveType = saveType
      this.$refs.modeleSave.dialogVisible = true
      if (item) {
        this.$refs.modeleSave.form.directoryName = item.directoryName
        this.$refs.modeleSave.form.filterOption = item.filterOption
      }
    },
    handleClose(tag) {
      this.form.filterOptions.splice(this.form.filterOptions.indexOf(tag), 1)
      this.form.contentList.forEach(item => {
        if (item.filterOption === tag) {
          item.filterOption = ''
        }
      })
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm() {
      const { inputValue } = this
      if (inputValue) {
        this.form.filterOptions.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    preview() {
      // 没保存之前保存在浏览器缓存
      let htmlStr = ''
      this.form.contentList.forEach(d => {
        if (d.content) {
          // eslint-disable-next-line no-const-assign
          htmlStr += d.content
        }
      })
      if (htmlStr) {
        localStorage.setItem(`htmlStr${this.id}`, htmlStr)
        const routeUrl = this.$router.resolve({
          path: '/template-preview',
          query: { id: this.id },
        })
        window.open(routeUrl.href, '_blank')
      } else {
        error('内容为空，无法预览')
      }
    },
    onEnd(event) {
      this.activeIndex = event.newIndex
    },
    delSection(index) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.form.contentList.splice(index, 1)
        this.activeIndex = 0
        this.htmlStr = this.form.contentList[0].content
      })
    },
    getTemplateInfo() {
      GetTemplateById({ code: this.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          const dd = resData.data
          this.form.code = dd.code
          this.form.templateName = dd.templateName
          this.form.description = dd.description
          this.form.directoryId = dd.directoryId
          this.form.language = dd.language
          this.form.area = dd.area
          this.form.contentList = dd.contentList
          if (dd.labelIdList) {
            this.form.labelIdList = dd.labelIdList.filter(num => num !== 0)
          }
          if (this.c === '3') {
            this.form.templateName = `${this.form.templateName}复制`
            this.form.code = ''
          }
          this.form.filterOptions = dd.filterOptions
        }
      })
    },
    sectionClick(item, index) {
      this.htmlStr = item.content
      this.activeIndex = index
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    change() {
      if (!this.isInit) {
        this.form.contentList[this.activeIndex].content = this.htmlStr
      }
      this.isInit = false
    },
    back() {
      this.$router.push({
        path: this.backPath[this.t],
      })
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.contentList.forEach((m, index) => {
            if (m.directoryName.length === 0) {
              this.form.contentList.splice(index, 1)
            }
          })
          //   c: this.$route.params.c, // 新增1 修改 2 复制 3
          this.form.operateType = Number(this.c) === 2 ? 2 : 1
          SaveOrUpdateTemplate(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
  .directoryName {
    cursor: move;
  }
  .doc-dropdown .btn-secondary,
  .doc-dropdown .btn-secondary:hover {
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .feather-more-vertical {
    color: #6e6b7b !important;
  }
  .editor-wrap {
    height: 100%;
    min-height: 400px;
    border: 1px solid #ebe9f1;
    align-items: baseline;
    border-radius: 4px;
    .doc-left {
      width: 260px;
      border-right: 1px solid #ebe9f1;
      height: 100%;
      min-height: 400px;
      .add-doc {
        padding: 10px;
      }
      .section-item {
        padding: 10px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon-w_wendang {
          margin-right: 10px;
        }
        i {
          font-size: 20px;
        }
        .el-input-group__append,
        [dir] .el-input-group__prepend {
          padding: 0 5px !important;
        }
      }
      .section-operate {
        display: none;
        position: absolute;
        right: 10px;
      }
      .section-item:hover {
        .section-operate {
          display: block;
        }
      }
      .section-item.doc-active {
        border-left: 2px solid #0d40b2;
        color: #0d40b2;
        position: relative;
      }
      .tag-title {
        padding: 10px;
        border-top: 1px solid #ebe9f1;
        border-bottom: 1px solid #ebe9f1;
        align-items: center;
        /*color: #b9b9c3;*/
      }
    }
    .doc-right {
      width: calc(100% - 260px);
      height: 100%;
      .doc-search-wrap {
        width: 100%;
        padding: 0 20px;
        border-bottom: 1px solid #ebe9f1;
        height: 40px;
        .doc-search-left {
          width: 80%;
          .el-input__inner {
            border: none !important;
          }
        }
        .el-dropdown {
          display: flex;
          align-items: center;
        }
      }

      .doc-li {
        /*transition: all .2s;*/
        cursor: pointer;
        padding: 20px 10px;
        border-bottom: 1px solid #ebe9f1;
        color: #6e6b7b;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .feather-more-vertical {
          margin-right: 10px;
          position: relative;
          top: 2px;
          opacity: 0;
        }
        .documentName {
          /*margin-left: 10px;*/
          font-size: 12px;
          color: #6e6b7b;
        }
      }
      .doc-li:hover {
        transform: translateY(-4px);
        box-shadow: 0 3px 10px 0 #ebe9f1;
        .feather-more-vertical {
          opacity: 1;
        }
      }
    }
  }
  .input-new-tag .el-input__inner{
    width: 200px;
  }
</style>
<style>
  .w-e-text-container table td,
  .w-e-text-container table th {
    border: 1px solid var(--w-e-textarea-border-color);
    padding: 3px 5px;
  }
</style>
