<template>
  <el-dialog
    :title="saveType === 1 ? '新增模块' : '编辑模块'"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="120px"
    >
      <el-form-item
        label="模块名称"
        prop="directoryName"
        :rules="[ { required: true, message: '模块名称不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.directoryName"
        />
      </el-form-item>
      <el-form-item label="过滤项">
        <el-select
            clearable
            v-model="form.filterOption"
            placeholder="请选择模版类型"
        >
          <el-option
            v-for="(item,index) in filterOptions"
            :key="index"
            :value="item"
            :label="item"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    filterOptions: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        filterOption: '',
        directoryName: '',
      },
      saveType: 1,
    }
  },
  methods: {
    dialogClose() {
      this.form = {
        filterOption: '',
        directoryName: '',
      }
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.dialogVisible = false
          this.$emit('handleRefensh')
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
